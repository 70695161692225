module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.14.0_gatsby@3.13.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Car Loans Canada","short_name":"CLC","start_url":"/","background_color":"#ffffff","theme_color":"#16C686","display":"minimal-ui","icon":"brands/sonnet/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8524fa502a9a840022b0630e02655a46"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mixpanel@3.5.0_qk5o47dybculm6xz5sw3d2wl3u/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"54e3e016500b6990a266353a484afdde","enableOnDevMode":false,"pageViews":"all","trackPageViewsAs":"PageView","getPageViewTransformerFn":"return (location) => ({\n                        location,\n                        appVersion: \"credit-classic-1.1.8\",\n                        versionNumber: \"1.1.8\",\n                    })"},
    },{
      plugin: require('../node_modules/.pnpm/@autocorp+gatsby-plugin-recaptcha@2.0.26/node_modules/@autocorp/gatsby-plugin-recaptcha/gatsby-browser.js'),
      options: {"plugins":[],"siteKey":"6LfUau0UAAAAAOCE-FUpHOwIX9ssREUBgX6ANSTc","apiUrl":"https://s2mn30ugc7.execute-api.us-east-1.amazonaws.com/prod/recaptcha","siteAction":"clc-loan-application","explicit":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@3.14.0_qk5o47dybculm6xz5sw3d2wl3u/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WT59G7X","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"variation":"credit-classic-1.1.8"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/.pnpm/@autocorp+gatsby-plugin-sentry@2.1.40/node_modules/@autocorp/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"dsn":"https://e781d308ac7e474b8c2d48b15c9c7a7b@o373957.ingest.sentry.io/5213785","debug":false,"release":"clc-loan-application@credit-classic-1.1.8","environment":"production","allowUrls":["carloanscanada.com"]},
    },{
      plugin: require('../node_modules/.pnpm/@autocorp+gatsby-theme-application@6.5.34/node_modules/@autocorp/gatsby-theme-application/gatsby-browser.js'),
      options: {"plugins":[],"config":"/opt/build/repo/apps/multi-step-app/brands/sonnet/config.js","pixelConfig":"/opt/build/repo/apps/multi-step-app/brands/sonnet/pixel-config.js","images":{"logo":"/opt/build/repo/apps/multi-step-app/brands/sonnet/images/logo.png"},"hideBranding":false,"showContactUs":false,"countryLocale":"ca","customLayout":false,"disableInputTransitions":false,"leadspedia":{"apiDomain":"api.carloanscanada.com"},"gtm":{"id":"GTM-WT59G7X"},"sentry":{"dsn":"https://e781d308ac7e474b8c2d48b15c9c7a7b@o373957.ingest.sentry.io/5213785","deployMaps":true,"allowUrls":["carloanscanada.com"]},"twilio":{"verifyApiUrl":"https://verify.twilio.com/v2/Services","serviceSid":"acd68cda9eaea2f0b4daa4d89eaeb4d49cf4acd09ea88cd4b4ae8a629eae8a669cd48a629aae9cd6b2f4a4d6b4ce7a7a","verifyApiKey":"aa60e8d2b2d49264b4a8b4d69a8eb2eeb4ae92f4b2649cd6b2a89a649eaeb2f09c8e9cd8b4a8a4dab2ae92ee9e827a7a","verifyApiSecret":"9c8ad0aa9cf494b0a6dcaa66c8ae62c29cd8e09ecada72eac66294aaaaf4d89e9a8a60f0aed8d8d0c68ea27a"},"mixpanel":{"projectToken":"54e3e016500b6990a266353a484afdde"},"clientIpServiceUrl":"0fh8DJ0UG6FBnF5?ag^w","googleMapsApiKey":"AIzaSyDuhe6kQRxbQKgQxT6K5IDs9LaNbAn4kxE","recaptcha":{"key":"6LfUau0UAAAAAOCE-FUpHOwIX9ssREUBgX6ANSTc","api":"https://s2mn30ugc7.execute-api.us-east-1.amazonaws.com/prod/recaptcha"}},
    },{
      plugin: require('../node_modules/.pnpm/@autocorp+gatsby-theme-mui-theme@1.0.19/node_modules/@autocorp/gatsby-theme-mui-theme/plugins/gatsby-theme-mui-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@3.0.1_k6ctaa4ydjpvcx3accetd43rmq/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
